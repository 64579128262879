<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Regresar a pantalla anterior" @click="gotoDashboard" v-if="!es_proveedor && $ver(['Dashboard'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }" class="icon btn-icon" />
            Regresar
          </a>
        </transition>
        <transition name="slide-fade" mode="out-in">
          <span>
            <label class="h4 align-sub">Movimientos del Sistema</label>
          </span>
        </transition>
      </div>
    </div>
    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-12">
          <div class="table-responsive site-table2-responsive">
            <div class="row">
              <div class="col-lg-12 text-right">
                <b-button class="btn-detail m-2"
                  @click="!loading ? updateTable() : undefined"
                  :disabled="loading"
                  v-b-popover.hover="'Actualizar listado'">
                  <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
                  <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'sync-alt', }" class="icon btn-icon"/>
                </b-button>
              </div>
            </div>
            <!-- Tabla de datos -->
            <ag-grid-vue
                class="ag-grid-table ag-theme-balham"
                style='height: 65vh'
                :localeText="locale"
                :animateRows="true"
                rowSelection="single"

                :gridOptions="gridOptions"
                :context="context"
                :suppressDragLeaveHidesColumns="true"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :statusBar="statusBar"
                :overlayNoRowsTemplate="$overlayNoRowsTemplate()"
                :frameworkComponents="frameworkComponents"

                :rowModelType="rowModelType"
                :cacheBlockSize="paginationPageSize"

                @grid-ready="onGridReady"
                @gridSizeChanged="gridSizeChanged"
                @row-selected="rowSelected"
                @rowDoubleClicked="rowDblClick"
                @column-resized="saveColumnState"
                @column-moved="saveColumnState"
                @sort-changed="saveColumnState"
                @first-data-rendered="gridFirstDataRendered">
            </ag-grid-vue>
          </div>
        </div>
      </transition>
    </div>
    <b-modal
        id="bv-modal-evento" size="xl" centered hide-footer content-class="site-modal"
        :header-text-variant="'light'">
        <template #modal-title>Detalles de Evento</template>
        <div class="row">
          <div class="col-12 col-md-6">
            <table class="table table-info table-light">
              <tbody>
                <tr>
                  <td class="table-dark">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'building', }" class="icon btn-icon"/> Fecha
                  </td>
                  <td>{{ selectedRow.Fecha || '' }}</td>
                </tr>
                <tr>
                  <td class="table-dark">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'id-card', }" class="icon btn-icon"/> Usuario
                  </td>
                  <td>{{ selectedRow.Usuario || '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-md-6">
            <table class="table table-info table-light">
              <tbody>
                <tr>
                  <td class="table-dark">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'building', }" class="icon btn-icon"/> Sección
                  </td>
                  <td>{{ selectedRow.Seccion || '' }}</td>
                </tr>
                <tr>
                  <td class="table-dark">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'id-card', }" class="icon btn-icon"/> IP
                  </td>
                  <td>{{ selectedRow.Ip || '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h6>EVENTO: {{ selectedRow.Evento || '' }}</h6>
        <div v-if="hasQuery">
          <prism class="line-numbers auto" :language="'sql'">{{ evento || '' }}</prism>
        </div>
        <div v-else>
        </div>
      </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';

import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import AgCellUserAgent from '@/components/AgCellUserAgent.vue';
import AgCellPopover from '@/components/AgCellPopover.vue';
import AgStatusBar from '@/components/AgStatusBar.vue';
import { mapState } from 'vuex';
import Prism from 'vue-prism-component';
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/components/prism-sql'; // need this

export default {
  components: {
    AgGridVue,
    Prism,
  },
  props: [
    'es_proveedor',
  ],
  data() {
    return {
      loading: false,
      hasQuery: false,
      evento: '',
      /** Configuracion de tabla ag-grid */
      // eslint-disable-next-line no-undef
      locale: AG_GRID_LOCALE_ES,
      context: null,
      gridOptions: {
        nameTable: 'agBitacora',
        suppressMenuHide: true,
      },
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        sortable: true,
        filter: true,
        unSortIcon: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'statusBarComponent', align: 'left' },
          { statusPanel: 'agSelectedRowCountComponent' },
        ],
      },
      frameworkComponents: {
        userAgentTemplate: AgCellUserAgent,
        popoverTemplate: AgCellPopover,
        statusBarComponent: AgStatusBar,
      },
      selectedRow: {},
      selectedRowKeys: [],
      columnDefs: [
        {
          headerName: '#',
          field: 'BitacoraId',
          filter: false,
          pinned: 'left',
          width: '50px',
          resizable: false,
          cellClass: 'text-center line-numbers',
          cellRenderer: 'loadingRenderer',
        },
        {
          headerName: 'Fecha',
          field: 'Fecha',
          cellClass: 'text-center',
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Usuario',
          field: 'Usuario',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Evento',
          field: 'Evento',
          cellRendererSelector: () => ({
            component: 'popoverTemplate',
            params: {
              popoverText: 'Doble click para ver detalles',
            },
          }),
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Seccion',
          field: 'Seccion',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'IP',
          field: 'Ip',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'UserAgent',
          field: 'UserAgent',
          cellRendererSelector: () => ({
            component: 'userAgentTemplate',
          }),
          filter: 'agTextColumnFilter',
          suppressMenu: true,
        },
      ],
      rowModelType: 'serverSide',
      paginationPageSize: 30,
      firstRender: true,
      filters: '',
    };
  },
  computed: {
    ...mapState(['aggrid']),
  },
  mounted() {
    this.context = { componentParent: this };
  },
  methods: {
    // Evento cuando la tabla se inicializa
    onGridReady(params) {
      // Creamos variable global de params (propiedates y funciones de agGrid)
      this.agGrid = params;

      // Llamada a la funcion que actualiza la tabla
      this.getData();
      this.gridSizeChanged(this.agGrid);
    },
    // Metodo para ajustar tamaño de tabla cuando las columnas no cubren por completo el grid
    gridSizeChanged(params) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        if (!column.colDef.autoSize) { allColumnIds.push(column.colId); }
      });

      // params.columnApi.autoSizeColumns(allColumnIds);
    },
    saveColumnState() {
      const columnState = this.agGrid.columnApi?.getColumnState();
      this.$store.commit('SAVE_AGGRIDSTATE', { tblname: `${this.gridOptions?.nameTable}State`, columnstate: columnState });
    },
    gridFirstDataRendered() {
      const columnState = this.aggrid[`${this.gridOptions?.nameTable}State`];
      if (columnState) {
        this.agGrid.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },
    // Metodo para actualizar filas seleccionadas
    rowSelected(params) {
      // eslint-disable-next-line prefer-destructuring
      const selectedRow = params.api.getSelectedNodes()[0];
      this.selectedRowKeys = selectedRow.data.BitacoraId;
      this.selectedRow = selectedRow?.data;
    },
    // Metodo para abrir modal al dar doble click en una fila
    rowDblClick(/* params */) {
      if (this.$permisos_or([['Bitacora', 'Ver'], ['Bitacora', 'Editar']])) {
        this.verDetalles(this.selectedRow.Evento, this.selectedRow.Query);
      }
    },
    // Metodo para limpiar todos los filtros
    clearFilters() {
      if (Object.entries(this.filters.filterModel).length) this.loading = true;
      this.agGrid.api.setFilterModel(null);
    },
    // Metodo para actualiza datos de la tabla incluyendo filtros
    updateTable() {
      this.getData();
    },
    // Metodo para actualizar datos de la tabla (server - side)
    getData() {
      this.agGrid.api.setServerSideDatasource(
        this.$createDataSource(
          this,
          apiClient,
          'bitacora', // ruta
        ),
      );
    },
    verDetalles(evento, query) {
      this.hasQuery = query.length !== 0;
      this.evento = query.length === 0 ? evento : query;
      this.$bvModal.show('bv-modal-evento');
    },
    gotoDashboard() {
      this.$router.push({ name: '/dashboard' }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
